import axios from "axios";
import { errorAxios, getHeaders, getUrl } from "../functions/Api";

//API REQUEST/RESPONSE CREATE NEW ITEM
export const createInventoryAPI = async (formData) => {
  const config = {
    method: "POST",
    url: `${getUrl}/inventory/save`,
    data: formData,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ALL NEW ITEM
export const readInventoryAPI = async () => {
  const config = {
    method: "GET",
    url: `${getUrl}/inventory/getAll`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE UPDATE ITEM
export const updateInventoryAPI = async (formData) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/inventory/update`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE DELETE ITEM
export const deleteInventoryAPI = async (formData) => {
  const config = {
    method: "DELETE",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/inventory/removeInventoryFromMachine`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ITEM
export const itemInventoryAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/inventory/getInventory/${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const readInventoryMachineAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/inventory/getAll?idMachine=${key}&withDetails=true`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const deleteAllInventoryAPI = async (id) => {
  const config = {
    method: "DELETE",
    headers: getHeaders(),
    url: `${getUrl}/inventory/removeByIdMachine/${id}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};
