import axios from "axios";
import { errorAxios, getHeaders, getUrl } from "../functions/Api";

//API REQUEST/RESPONSE CREATE NEW ITEM
export const createPrescriptionAPI = async (formData) => {
  const config = {
    method: "POST",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/prescription/save`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ALL NEW ITEM
export const readPrescriptionAPI = async (formData) => {
  const key = formData === undefined ? "" : formData;
  const config = {
    method: "GET",
    headers: getHeaders(),
    url: `${getUrl}/prescription/getAll` + key,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE UPDATE ORDER
export const confirmPrescriptionAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/prescription/qr/confirmDelivery/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE VERIFY ORDER
export const verifyPrescriptionAPI = async (formData) => {
  const config = {
    method: "POST",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/prescription/qr/verify`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE UPDATE ORDER
export const updateIdQR = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/prescription/updateIdQr/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};