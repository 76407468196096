import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { urlLogOut } from "./../settings";
import { privateRoutes } from "./Routes";
import { useAuth } from "./authContext";

const ProtectedLayout = lazy(() => new Promise((resolve) => { setTimeout(() => resolve(import("./layout/protectedLayout")), 1500); }));

export const ProtectedRoute = () => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to={urlLogOut} />;
  }

  return (
    <>
      <Routes>
        <Route path="" element={<Navigate to="/login" replace />} />
        {privateRoutes.map(({ key, path, Component }) => {
          return (
            <Route key={key} path={`/${path}`} element={<ProtectedLayout />}>
              <Route key={key} path={`/${path}`} element={<Component />} />
            </Route>
          );
        })}
      </Routes>
    </>
  );
};
