import axios from "axios";
import { errorAxios, getHeaders, getUrl } from "../functions/Api";


export const updateKioskMedicalConsultAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/medicalConsult/updateKioskRecordId/${key}`,
  };
  try {
    return await axios(config);
  } catch (err) {
    return errorAxios(err);
  }
};

export const updateMedicalConsultAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/medicalConsult/update/${key}`,
  };
  try {
    return await axios(config);
  } catch (err) {
    return errorAxios(err);
  }
};

export const createMedicalConsultAPI = async (formData) => {
  const config = {
    method: "POST",
    url: `${getUrl}/medicalConsult/save`,
    data: formData,
    headers: getHeaders(),
  };
  try {
    return await axios(config);
  } catch (err) {
    return errorAxios(err);
  }
};
export const itemMedicalConsultAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/medicalConsult/getById/${key}`,
    headers: getHeaders(),
  };
  try {
    return await axios(config);
  } catch (err) {
    return errorAxios(err);
  }
};

export const readMedicalConsultAPI = async (formData) => {
  const key = formData || "";
  const config = {
    method: "GET",
    url: `${getUrl}/medicalConsult/getAll${key}`,
    headers: getHeaders(),
  };
  try {
    return await axios(config);
  } catch (err) {
    return errorAxios(err);
  }
};

export const deleteMedicalConsultAPI = async (key) => {
  const config = {
    method: "DELETE",
    headers: getHeaders(),
    url: `${getUrl}/medicalConsult/delete/${key}`,
  };
  try {
    return await axios(config);
  } catch (err) {
    return errorAxios(err);
  }
};