import { urlApi, urlLogOut } from "./../settings";
import { createFullDateTimeToken } from "./DateTime";

export const getUrl = urlApi;
export * from "./../api/AddAppointmentItemService";
export * from "./../api/AdmissionService";
export * from "./../api/AppointmentDateService";
export * from "./../api/AuthenticationService";
export * from "./../api/CategoryService";
export * from "./../api/ClinicalCaseService";
export * from "./../api/ClinicalHistoryService";
export * from "./../api/EmployeeService";
export * from "./../api/EvolutionNoteService";
export * from "./../api/InventoryService";
export * from "./../api/KioskService";
export * from "./../api/MachineService";
export * from "./../api/MedicalConsultService";
export * from "./../api/OrderService";
export * from "./../api/PrescriptionService";
export * from "./../api/ProductService";
export * from "./../api/RecordService";
export * from "./../api/SettingService";
export * from "./../api/VitalSignService";

export const errorAxios = (error) => {
  if (error.response) {
    console.error("Error response: ", error.response);
    return {
      error: error.response,
      data: [],
    };
  }
  else if (error.request) console.error("Error request: ", error.request);
  else console.trace("Error General", error);
  return {
    data: [],
  };
};

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  if (userData) {
    if (userData.token && createFullDateTimeToken(userData.expired)) {
      return "Bearer " + userData.token;
    }
  }
  window.localStorage.setItem("user", null);
  return window.location.replace(urlLogOut);
};

export const getHeaders = () => {
  return {
    Authorization: getToken(),
    Vary: "Accept-Encoding, Origin",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
  };
};
