import "./App.css";
import React from "react";
import { App } from "./App";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
