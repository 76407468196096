import axios from "axios";
import { errorAxios, getHeaders, getUrl } from "../functions/Api";

export const updateRecordMemberAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/records/updateMemberId/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const updateRecordAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/records/update/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const createRecordAPI = async (formData) => {
  const config = {
    method: "POST",
    url: `${getUrl}/records/save`,
    data: formData,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const itemRecordAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/records/getById/${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};
export const itemRecordDocumentAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/records/getByNoDocument/${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};


export const readRecordAPI = async (formData) => {
  const key = formData === undefined ? "" : formData;
  const config = {
    method: "GET",
    url: `${getUrl}/records/getAll${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const deleteRecordAPI = async (key) => {
  const config = {
    method: "DELETE",
    headers: getHeaders(),
    url: `${getUrl}/records/delete/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};