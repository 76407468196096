import axios from "axios";
import { getHeaders, getUrl, errorAxios } from "../functions/Api";

//API REQUEST/RESPONSE CREATE NEW ITEM
export const createProductAPI = async (formData) => {
  const config = {
    method: "POST",
    url: `${getUrl}/product/save`,
    data: formData,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ALL NEW ITEM
export const readProductAPI = async (formData) => {
  const key = formData === undefined ? "" : formData;
  const config = {
    method: "GET",
    url: `${getUrl}/product/getAll${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE UPDATE ITEM
export const updateProductAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/product/update/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE DELETE ITEM
export const deleteProductAPI = async (key) => {
  const config = {
    method: "DELETE",
    headers: getHeaders(),
    url: `${getUrl}/product/delete/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ITEM
export const itemProductAPI = async (key) => {
  const config = {
    method: "GET",
    url: `${getUrl}/product/getProduct/${key}`,
    headers: getHeaders(),
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};
