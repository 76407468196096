import { getUrl, errorAxios } from "../functions/Api";
import axios from "axios";

export const userLogin = async (formData) => {
  const config = {
    method: "POST",
    url: `${getUrl}/auth/login`,
    data: formData,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

export const fetchUserData = async (token) => {
  return await axios({
    method: "GET",
    url: `${getUrl}/auth/userInfo`,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};
