import React from "react";

import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { createFullDateTimeToken } from "../functions/DateTime";
import { fetchUserData, userLogin } from "./../functions/Api";
import { urlLogIn, urlLogOut } from "./../settings";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("user", null);
  // call this function when you want to authenticate the user
  const login = async (data) => {
    const response = await userLogin(data);
    if (response.status === 200) {
      const result = await fetchUserData(response.data.token);
      setUser({
        token: response.data.token,
        profile: result.data,
        expired: createFullDateTimeToken()
      });
      navigate(urlLogIn);
      return true;
    } else {
      alert("credenciales incorrectas");
      return false;
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate(urlLogOut, { replace: true });
  };

  const value = { user, login, logout };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
