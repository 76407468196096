//variables
const dateOptions = {
  weekday: "long",
  year: "numeric",
  day: "numeric",
  month: "long",
};

const timeOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
};

//local functions
function getFormatDate(month) {
  if (month <= 9) {
    return "0" + month;
  }
  return month;
}

//Only Time
export function getTimeApp(date) {
  //return 00:00:00 AM
  return date.toLocaleString("en-US", timeOptions);
}
export function getTime(date) {
  //return 00:00 AM
  const newFormat = Object.assign({}, timeOptions);
  delete newFormat.second;
  return date.toLocaleString("en-US", newFormat);
}

//Only Date
export function getDateApp(date) {
  // Lunes, 30 de diciembre de 2022
  return date.toLocaleDateString("es-gt", dateOptions);
}
export function convertDateByForm(newDate) {
  //2022-12-31
  const date = new Date(newDate);
  return (
    date.getFullYear() +
    "-" +
    getFormatDate(date.getMonth() + 1) +
    "-" +
    getFormatDate(date.getDate())
  );
}
export function createDateByForm() {
  //2022-12-31
  const newDate = new Date();
  return convertDateByForm(newDate);
}

//Date and Time
export function convertFullDateTime(oldDate) {
  //viernes, 30 de diciembre de 2022, 6:40:32 PM
  const newDate = new Date(oldDate);
  const date = getDateApp(newDate);
  const time = getTimeApp(newDate);
  return date + ", " + time;
}
export function getFullActualDateTime() {
  //viernes, 30 de diciembre de 2022, 6:40:32 PM
  const newDate = new Date();
  return convertFullDateTime(newDate);
}
export function convertApiDateTime(oldDate) {
  //30/12/2022, 6:40:32 PM
  const newDate = new Date(oldDate);
  const date =
    getFormatDate(newDate.getDate()) +
    "/" +
    getFormatDate(newDate.getMonth() + 1) +
    "/" +
    newDate.getFullYear();
  return date + ", " + getTime(newDate);
}
export function createFullDateTimeApi() {
  //2022-11-10T10:30:51:122Z
  const newDate = new Date();
  const date = convertDateByForm(newDate);
  const time =
    getFormatDate(date.getHours()) +
    ":" +
    getFormatDate(date.getMinutes()) +
    ":" +
    getFormatDate(date.getSeconds()) +
    ":" +
    getFormatDate(date.getMilliseconds());
  return date + "T" + time + "Z";
}
export function createFullDateTimeToken(compare) {
  //2022-11-10T10:30:51:122Z
  if (compare) {
    const afterCompare = new Date(compare).toISOString();
    const beforeCompare = new Date().toISOString();
    return afterCompare > beforeCompare;
  }
  const date = new Date();
  const time = date.getHours() + 3;
  const newDate = date.setHours(time);
  return new Date(newDate).toISOString()
}
