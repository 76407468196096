export * from "./useLocalStorage";
export * from "./authContext";
export * from "./authRoute";

//PROFILES
export const profiles = [
  {
    code: 1,
    name: "Super Usuario",
  },
  {
    code: 2,
    name: "Administrador",
  },
  {
    code: 3,
    name: "Medico",
  },
  {
    code: 4,
    name: "Laboratorio",
  },
  {
    code: 5,
    name: "Farmacia e inventarios",
  },
  {
    code: 6,
    name: "Enfermeria",
  },
  {
    code: 7,
    name: "Archivo",
  },
];
