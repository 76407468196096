import axios from "axios";
import { errorAxios, getHeaders, getUrl } from "../functions/Api";

//API REQUEST/RESPONSE CREATE NEW ITEM
export const createOrderAPI = async (formData) => {
  const config = {
    method: "POST",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/order/qr/save`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE READ ALL NEW ITEM
export const readOrderAPI = async (formData) => {
  const key = formData === undefined ? "" : formData;
  const config = {
    method: "GET",
    headers: getHeaders(),
    url: `${getUrl}/order/getAll` + key,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE UPDATE ORDER
export const confirmOrderAPI = async (formData, key) => {
  const config = {
    method: "PUT",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/order/qr/confirmDelivery/${key}`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};

//API REQUEST/RESPONSE VERIFY ORDER
export const verifyOrderAPI = async (formData) => {
  const config = {
    method: "POST",
    data: formData,
    headers: getHeaders(),
    url: `${getUrl}/order/qr/verify`,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return errorAxios(err);
    });
};
