//export const urlApi = 'http://gateway-lb-50691249d7911cf8.elb.us-east-1.amazonaws.com/api/v1'
export const urlApi = 'https://gateway.contasa-services.com/api/v1'

export const urlLogIn = "/app/inventarios/";
export const urlProtectd = "/app/";
export const urlLogOut = "/login/";
export const urlAuth = "login";
//Namesc
export const appVersion = "1.6.20";
export const appName = "Plataforma GSF";

//Times
export const timeAlert = 5000;
export const timeModal = 1000;
export const timeLoading = 1000;
