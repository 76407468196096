import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Loading, Page404 } from "./functions/Pages";

import { AuthProvider, ProtectedRoute } from "./auth/";

export function App() {
  const LoginPage = lazy(
    () =>
      new Promise((resolve) => {
        setTimeout(() => resolve(import("./auth/login/LoginPage")), 1000);
      })
  );

  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/*" element={<Page404 />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/app/*" element={<ProtectedRoute />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}
